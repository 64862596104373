<template>
    <div class="container">
      <el-row :gutter="20">
        <el-col :span="10" :xs="24" >
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <span>个人信息</span>
            </div>
            <div>
              <div class="text-center">
                <userAvatar :user="user" />
              </div>
              <ul class="list-group list-group-striped">
                <li class="list-group-item">
                  <svg-icon icon-class="user" /> 用户名称
                  <div class="pull-right">{{ user.username }}</div>
                </li>
                <li class="list-group-item">
                  <svg-icon icon-class="phone" /> 手机号码
                  <div class="pull-right">{{ user.mobile }}</div>
                </li>
                <li class="list-group-item">
                  <svg-icon icon-class="email" /> 用户邮箱
                  <div class="pull-right">{{ user.email }}</div>
                </li>
                <li class="list-group-item">
                  <svg-icon icon-class="tree" /> 所属机构
                  <div class="pull-right" v-if="user.dept">{{ user.dept.name }} / {{ postGroup }}</div>
                </li>
                <li class="list-group-item">
                  <svg-icon icon-class="peoples" /> 所属角色
                  <div class="pull-right">{{ roleGroup }}</div>
                </li>
                <li class="list-group-item">
                  <svg-icon icon-class="date" /> 创建日期
                  <div class="pull-right">{{ parseTime(user.createtime) }}</div>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
        <el-col :span="14" :xs="24"  >
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>基本资料</span>
            </div>
            <el-tabs v-model="activeTab">
              <el-tab-pane label="基本资料" name="userinfo">
                <userInfo :user="user" />
              </el-tab-pane>
              <el-tab-pane label="修改密码" name="resetPwd">
                <resetPwd :user="user" />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  import userAvatar from "./profile/userAvatar";
  import userInfo from "./profile/userInfo";
  import resetPwd from "./profile/resetPwd";
  import { getUserProfile } from "@/api/core/system/user";
  
  export default {
    name: "Profile",
    components: { userAvatar, userInfo, resetPwd },
    data() {
      return {
        user: {},
        activeTab: "userinfo"
      };
    },
    created() {
      this.getUser();
    },
  
    computed:{
      postGroup(){
        if(Array.isArray(this.user.posts)){
          return this.user.posts.reduce(function(total, currentValue, currentIndex, arr){
            return total + currentValue.name + " "
          }, "")
        }
        return ""
      },
      roleGroup(){
        if(Array.isArray(this.user.roles)){
          return this.user.roles.reduce(function(total, currentValue, currentIndex, arr){
            return total + currentValue.name + " "
          }, "")
        }
        return ""
      }
    },
    methods: {
      getUser() {
        getUserProfile().then(response => {
          this.user = response.data.user;
  
        });
      }
    }
  };
  </script>

<style lang="scss" scoped>
.container {
    background-color: $base-container-color;
}
.personal-center-user-info {
    padding: 20px;
    text-align: center;
    height: 500px;
}
.personal-center-user-info-full-name{
    margin-top: 15px;
    font-size: 24px;
    font-weight: 500;
    color: #262626;
}
.personal-center-user-info-description {
    margin-top: 8px;
}
.personal-center-user-info-list {
    margin-top: 28px;
    margin-left: 20px;
    margin-right: 20px;
    &::v-deep {
        i {
            line-height: 21px;
        }
    }
}
.form-input{
    width: 60%;
    min-width: 200px;
}

.right-panel{
    height: 500px;
}

</style>
  